import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link, PageProps } from "gatsby";
import { Seo } from "../../components/seo";
import NextPageButton from "../../components/next-page-button";
import { StaticImage } from "gatsby-plugin-image";

const Start3 = ({ location }: PageProps): React.ReactElement => (
  <Chakra.Box width="100%">
    <Seo title="Start" location={location} />
    <Chakra.Flex
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Chakra.Flex
        maxWidth="908px"
        py="40px"
        px="40px"
        justifyContent="space-between"
        alignItems={{ base: "start", lg: "center" }}
        height="100%"
        m="auto"
        width="100%"
        position="relative"
      >
        <Chakra.Box maxWidth="354px">
          <Chakra.Text
            as="h4"
            pb="30px"
            fontSize="28px"
            letterSpacing="0.22px"
            lineHeight="20px"
            fontWeight="600"
          >
            Solution
          </Chakra.Text>
          <Chakra.VStack
            textAlign="left"
            alignItems="start"
            justifyContent="start"
            spacing="20px"
            fontSize="14px"
            letterSpacing="0.11px"
            lineHeight="20px"
            fontWeight="300"
          >
            <p>
              Experimentation is the only reliable way of testing your big new
              idea, developing that new product feature or launching a
              high-performing marketing or advertising campaign. It helps you to
              deliver more projects successfully.
            </p>
            <p>
              Experiments are highly targeted, fast, low-cost and short
              duration. Experiments aim to test key product and market
              assumptions prior to mass-market launch that, if not tested,
              represent significant risk in the project delivery process.
            </p>
            <p>
              We know that what customers say, and do, is very different. There
              is a behaviour and intention gap that exists with customers.
              Experimentation allows you to understand if what customers are
              telling you is true.
            </p>
            <p>
              Learn faster with experimentation so that you can make decisions
              with more confidence. Experimentation is a true competitive
              advantage.
            </p>
          </Chakra.VStack>
        </Chakra.Box>
        <Chakra.Box display={{ base: "none", lg: "unset" }}>
          <StaticImage
            src="../../images/start-slides/start-slide-3.png"
            alt=""
          />
        </Chakra.Box>
      </Chakra.Flex>
    </Chakra.Flex>
    <NextPageButton as={Link} to="/google">
      Start Course
    </NextPageButton>
  </Chakra.Box>
);

export default Start3;
